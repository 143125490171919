export const SUPPORT_EMAIL = 'node-react-template.support@aurecongroup.com'
export const JIRA_SUPPORT_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const ABOUT_URL = 'https://www.aurecongroup.com/expertise/environmental-management'
export const REQUEST_ACCESS_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/24'
export const MAX_FILE_NAME = 80

const config = {
  AUTH0_DOMAIN: 'auth.aurecongroup.digital',
  AUTH0_CLIENT_ID: 'Xi8AyQptXQ5Aik0pdzks3ldmkSJeNu4S',
  AUTH0_AUDIENCE: 'https://node-react-template.aurecongroup.digital',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  AUTH0_GLOBAL_ADMIN_ROLE: 'node-react-template Global Admin',
  MOBLIE_VIEW_MAX_WIDTH: 768,
}

export default config
